import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Rooms() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen">

        <Helmet>
        <title>Ağva Otel Saklı Bahçe | Odalar</title>
        <meta name="title" content="Ağva Saklı Bahçe Otel - Ağva Otelleri - Odalar" />
        <meta name="description" content= "Ağva Saklı Bahçe Otel'in eşsiz odalarında huzur ve konforu bir arada yaşayın. Doğanın kucağında huzurlu bir kaçamak için ideal adres! Göl manzaralı ve modern olanaklarıyla unutulmaz bir tatil için hazırız. Şimdi rezervasyon yapın ve Ağva'da hayalinizdeki tatili yaşayın!" />
        <meta property="og:title" content="Ağva Saklı Bahçe Otel | Odalar" />
        <meta property="og:description" content="Ağva Saklı Bahçe Otel'in eşsiz odalarında huzur ve konforu bir arada yaşayın. Doğanın kucağında huzurlu bir kaçamak için ideal adres! Göl manzaralı ve modern olanaklarıyla unutulmaz bir tatil için hazırız. Şimdi rezervasyon yapın ve Ağva'da hayalinizdeki tatili yaşayın!" />
        <meta property="og:image" content="./media/logo.png" />
        <meta property="og:url" content="https://www.agvasaklibahce.com/rooms" />
      </Helmet>

      <Navbar />
      <div>
        <FloatingWhatsApp
          phoneNumber="+905522927803"
          accountName="Saklı Bahçe"
          avatar="./media/logo.png"
          chatMessage={t("wpgreeting")}
        />
      </div>
      <div className="relative">
        <img
          src="/media/rooms/ahsap/ahsap2.jpg"
          alt="Ağva Otel - Ağva Villa Otel - Ağva Otel Odası"
          className="w-full h-[500px] object-cover"
        />
        
        <div class="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center mt-14">
          <p className="text-white text-5xl font-thin tracking-wider font-serif  uppercase">
            {t("rooms")}
          </p>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        <span className="text-lg font-thin text-center block mb-8">
          {t("roomsdesc")}
        </span>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="rounded-lg shadow-md overflow-hidden relative bg-slate-200 transition duration-500 hover:bg-green-50 hover:shadow-2xl">
            <div className="relative">
              <Link to={"/rooms/deluxe"}>
                <img
                  src="/media/rooms/deluxe/deluxe1.jpg"
                  alt="Ağva Otel - Ağva Villa Otel - Ağva Otel Odası"
                  className="w-full h-64 object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 flex items-center justify-center rounded-2xl hover:opacity-100">
                  <p className=" text-center text-lg font-bold text-white ">
                    {t("deluxe")}
                  </p>
                </div>
              </Link>
            </div>

            <div className="p-4 flex flex-col justify-end">
              <h1 className="text-xl font-bold text-center p-2">
                {t("deluxe")}
              </h1>
              <p className="text-gray-700 mb-14 text-center">
                {t("deluxedesc")}
              </p>
              <Link
              className="flex justify-center"
              to={"/rooms/deluxe"}
              >
                <button className="bg-yellow-700 text-white py-2 px-4 rounded-lg mt-auto hover:bg-blue-400 absolute bottom-2">
                  {t("inspect")}
                </button>
              </Link>
            </div>
          </div>

          <div className="rounded-lg shadow-md overflow-hidden relative  bg-slate-200 transition duration-500 hover:bg-green-50 hover:shadow-2xl">
            <div className="relative">
              <Link to={"/rooms/ahsap"}>
                <img
                  src="/media/rooms/ahsap/ahsap1.jpg"
                  alt="Ağva Otel - Ağva Villa Otel - Ağva Otel Odası - Ağva Ahşap Otel"
                  className="w-full h-64 object-cover"
                />

                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 flex items-center justify-center rounded-2xl hover:opacity-100">
                  <p className="text-center text-lg font-bold text-white">
                    {t("wood")}
                  </p>
                </div>
              </Link>
            </div>
            <div className="p-4 flex flex-col justify-end">
              <h1 className="text-xl font-bold text-center">{t("wood")}</h1>
              <p className="text-gray-700 mb-14 text-center">{t("wooddesc")}</p>
              <Link
                className="flex justify-center"
                to={"/rooms/ahsap"}
              >
                <button className="bg-yellow-700 text-white py-2 px-4 rounded-lg mt-auto hover:bg-blue-400 absolute bottom-2">
                  {t("inspect")}
                </button>
              </Link>
            </div>
          </div>

          <div className="rounded-lg shadow-md overflow-hidden relative  bg-slate-200 transition duration-500 hover:bg-green-50 hover:shadow-2xl">
            <div className="relative">
              <Link to={"/rooms/standart"}>
                <img
                  src="/media/rooms/standart/standart1.jpg"
                  alt="Ağva Otel - Ağva Villa  - Ağva Otel Odası"
                  className="w-full h-64 object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 flex items-center justify-center rounded-2xl hover:opacity-100">
                  <p className="text-white text-center text-lg font-bold">
                    {t("standart")}
                  </p>
                </div>
              </Link>
            </div>
            <div className="p-4 flex flex-col justify-end">
              <h1 className="text-xl font-bold text-center">{t("standart")}</h1>
              <p className="text-gray-700 mb-14 text-center">
                {t("standartdesc")}
              </p>
              <Link
                className="flex justify-center"
                to={"/rooms/standart"}
              >
                <button className="bg-yellow-700 text-white py-2 px-4 rounded-lg mt-auto hover:bg-blue-400 absolute bottom-2">
                  {t("inspect")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Rooms;
