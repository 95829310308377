import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
export default function NotFoundPage() {
    const {t} = useTranslation();
    let title = t("404");
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Navbar />
      <div className="container mx-auto my-72">
        <h1 className="text-4xl text-center mt-20">{t("404")}</h1>
        <p className="text-center mt-4">
          {t("404desc")}
        </p>
      </div>
      <Footer />
    </div>
  );
}
