import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function HomeMain() {
    const { t } = useTranslation();
  return (
    <div className="container mx-auto mb-5">
        <div className="border-b  mb-5">
          <h1 className="text-4xl text-center font-bold mt-20">
            {t("greeting")}
          </h1>
          <p className="text-center text-lg mt-4 mb-4 font-light">{t("p1")}</p>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2 mt-10 md:mt-0 ">
            <img
              src="/media/main4.jpg"
              alt="Ağva Yeşilliklerin Arasında Bir Ev - Ağva Saklı Bahçe Otel - Ağva Otel - Ağva Villa Otel"
              className="w-full rounded-lg h-[400px] lg:h-[600px] object-cover"
            />
          </div>

          <div className="w-full lg:w-1/2 items-center justify-center content-center">
            <h2 className="text-xl font-medium text-center md:text-left m-10">
              {t("p2")}
            </h2>
            <p className="text-center md:text-left mt-4 font-light ml-5">
              {t("p4")}
            </p>
          </div>
        </div>

        <div>
          <h2 className="text-3xl text-center font-normal mt-20">
            {t("greeting5")}
          </h2>
          <p className="text-center text-lg mt-4 mb-4 font-light">{t("p5")}</p>
          <div className="flex justify-center">
          <Link to="/reservation">
            <button className="bg-stone-900 text-white p-2 rounded-lg">
              {t("reservation")}
            </button>
          </Link>
          </div>
         
        </div>

      </div>
  )
}
