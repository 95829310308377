import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Responsive Fotograf Boyutlarına Göre Tekrar Bakılacak !.
function CarouselMain() {
  return (
    <div>
      <Carousel showThumbs={false} infiniteLoop autoPlay showArrows={false}>
      <div>
          <img
            className="h-[670px]   max-lg:h-[450px] object-cover "
            src="/media/galeri4.jpg"
            alt="Ağva Nehir Kıyısı - Ağva Otel - Ağva Villa Otel"
          />
        </div>
        <div>
          <img
            className="h-[670px]   max-lg:h-[450px] object-cover "
            src="/media/galeri11.jpg"
            alt="Ağva Nehir Kıyısı - Ağva Otel - Ağva Villa Otel"
          />
        </div>
        <div>
          <img
            className="h-[670px]   max-lg:h-[450px] object-cover "
            src="/media/main2.jpg"
            alt="Ağva Nehir Kıyısı - Ağva Otel - Ağva Villa Otel"
          />
        </div>
        <div>
          <img
            className="h-[670px]   max-lg:h-[450px] object-cover "
            src="/media/galeri10.jpg"
            alt="Ağva Nehir Kıyısı - Ağva Otel - Ağva Villa Otel"
          />
        </div>
        <div>
          <img
            className="h-[670px]   max-lg:h-[450px] object-cover"
            src="/media/main3.jpg"
            alt="Ağva Nehir Kıyısı - Ağva Otel - Ağva Villa Otel"
          />
        </div>
       
      </Carousel>
    </div>
  );
}

export default CarouselMain;
