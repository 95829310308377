import React from "react";
import { useTranslation } from "react-i18next";
import { PiHouseSimpleLight } from "react-icons/pi";
import { MdOutlineBathroom } from "react-icons/md";
import { SiGeneralelectric } from "react-icons/si";
import { BiLandscape } from "react-icons/bi";
import { PiTelevisionSimpleDuotone } from "react-icons/pi";
import { MdOutlineFastfood } from "react-icons/md";
import { SlSizeFullscreen } from "react-icons/sl";
import { IoBedOutline } from "react-icons/io5";
import { GoPerson } from "react-icons/go";

export default function PropsDeluxe() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-start">
        <h1 className="text-3xl tracking-wider font-sans">{t("deluxe")}</h1>
        <div className="my-4 text-center">
          <p className="font-extralight">{t("deluxedesc")}</p>
        </div>
        <hr className="border-2 border-black w-full ml-2 mt-2 "></hr>
      </div>
      <div className="mt-20">
        <div className="my-8 mx-12">
          <div className="flex items-center mb-4 space-x-1 border-b ">
            <PiHouseSimpleLight className="text-3xl mb-4" />
            <h2 className="text-3xl font-light mb-4">{t("room_features")}</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="border rounded-full p-16 shadow-2xl bg-gray-50 transition-all duration-500 hover:bg-gray-200">
              <div className="flex items-center space-x-1 mb-2">
                <MdOutlineBathroom className="text-lg" />
                <h2 className="text-lg font-semibold border-b ">
                  {t("bathroom_features")}
                </h2>
              </div>

              <ul className="list-disc list-inside">
                <li>{t("jacuzzi")}</li>
                <li>{t("whirlpool_bath")}</li>
                <li>{t("hair_dryer")}</li>
                <li>{t("bath")}</li>
                <li>{t("shower")}</li>
                <li>{t("toilet")}</li>
                <li>{t("slippers")}</li>
                <li>{t("free_toiletries")}</li>
              </ul>
            </div>

            <div className="border rounded-full p-16 shadow-2xl bg-gray-50 transition-all duration-500 hover:bg-gray-200">
              <div className="flex items-center space-x-1 mb-2 border-b ">
                <SiGeneralelectric className="text-lg" />
                <h2 className="text-lg font-semibold">
                  {t("general_features")}
                </h2>
              </div>
              <ul className="list-disc list-inside">
                <li>{t("heating")}</li>
                <li>{t("tile_marble_floor")}</li>
                <li>{t("soundproofing")}</li>
                <li>{t("mosquito_net")}</li>
                <li>{t("cleaning_products")}</li>
                <li>{t("fireplace")}</li>
                <li>{t("split_air_conditioning")}</li>
              </ul>
            </div>

            <div className="border rounded-full p-16 shadow-2xl bg-gray-50 transition-all duration-500 hover:bg-gray-200">
              <div className="flex items-center space-x-1 mb-2 border-b ">
                <BiLandscape className="text-lg" />
                <h2 className="text-lg font-semibold">{t("outdoor_features")}</h2>
              </div>
              <ul className="list-disc list-inside">
                <li>{t("garden_view")}</li>
                <li>{t("balcony")}</li>
                <li>{t("patio/terrace")}</li>
              </ul>
            </div>

            <div className="border rounded-full p-16 shadow-2xl bg-gray-50 transition-all duration-500 hover:bg-gray-200">
              <div className="flex items-center space-x-1 mb-2 border-b ">
                <PiTelevisionSimpleDuotone className="text-lg" />
                <h2 className="text-lg font-semibold">{t("media")}</h2>
              </div>
              <ul className="list-disc list-inside">
                <li>{t("flat_screen_tv")}</li>
                <li>{t("TV")}</li>
              </ul>
            </div>

            <div className="border rounded-full p-16 shadow-2xl bg-gray-50 transition-all duration-500 hover:bg-gray-200">
              <div className="flex items-center space-x-1 mb-2 border-b ">
                <MdOutlineFastfood className="text-lg" />
                <h2 className="text-lg font-semibold">
                  {t("food_and_drinks")}
                </h2>
              </div>
              <ul className="list-disc list-inside">
                <li>{t("electric_kettle")}</li>
                <li>{t("minibar")}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-8 mx-12">
          <div className="flex items-center mb-4 space-x-1 border-b ">
            <SlSizeFullscreen className="text-3xl mb-4" />
            <h1 className="text-3xl font-light mb-4">{t("room_size")}</h1>
          </div>

          <p className="text-gray-600">25 m2</p>
        </div>

        <div className="mb-8 mx-12">
          <div className="flex items-center mb-4 space-x-1 border-b ">
            <IoBedOutline className="text-3xl mb-4" />
            <h1 className="text-3xl font-light mb-4">{t("bed_options")}</h1>
          </div>

          <p className="text-gray-600">{t("1piece")} {t("double_bed")}</p>
        </div>

        <div className="mx-12">
          <div className="flex items-center mb-4 space-x-1 border-b  ">
            <GoPerson className="text-3xl mb-4" />
            <h1 className="text-3xl font-light mb-4">{t("max_capacity")}</h1>
          </div>

          <p className="text-gray-600">{t("person2")}</p>
          <p className="text-gray-600">{t("room2person")}</p>
        </div>
      </div>
    </div>
  );
}
