import React from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translationTR from './i18n/tr.json';
import translationEN from './i18n/en.json';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; 
import Home from './pages/Home';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import Rooms from './pages/Rooms';
import RoomDeluxe from './pages/RoomDeluxe';
import ScrollToTop from './components/ScrollToTop';
import NotFoundPage from './pages/NotFoundPage';
import Contact from './pages/Contact';
import Galery from './pages/Galery';
const resources = {
  en: {
    translation: translationEN
  },
  tr: {
    translation: translationTR
  }
};
const defaultLanguage = Cookies.get('lng') || 'tr';


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: defaultLanguage,
    interpolation: {
      escapeValue: false
    }
  });
  

function App() {
  useEffect(() => {
    Cookies.set('lng', defaultLanguage, { expires: 365 })
    const selectedLanguage = Cookies.get('lng') || defaultLanguage;
    i18n.changeLanguage(selectedLanguage);
  }, []);


  return (
    <I18nextProvider i18n={i18n}>
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/rooms/:types" element={<RoomDeluxe />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/galery" element={<Galery />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  </I18nextProvider>
  )
}

export default App
