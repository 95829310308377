import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
export default function RoomMain() {
    const { t } = useTranslation();
  return (
    <div className="container my-10">
    <div className="flex flex-wrap">
      
      <div className="w-full lg:w-1/3 flex justify-center">
        <div className="border rounded-2xl relative w-[100vh] ">
        <Link to={'rooms/deluxe'}>
        <img
            src="/media/rooms/deluxe/deluxe1.jpg"
            alt="Ağva Otel - Ağva Otel Oda - Ağva Saklı Bahçe Otel - Ağva Villa "
            className="w-full rounded-lg lg:h-72 h-[300px]"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 flex items-center justify-center rounded-2xl hover:opacity-100">
            <p className="text-white text-center text-lg font-bold">
              {t("deluxe")}
            </p>
          </div>
        </Link>
          
        </div>
      </div>
      <div className="w-full lg:w-1/3 flex justify-center">
        <div className="border rounded-2xl relative w-[100vh]">
          <Link to={'rooms/ahsap'}>
          <img
            src="/media/rooms/ahsap/ahsap1.jpg"
            alt="Ağva Otel - Ağva Villa Otel - Ağva Ahşap Otel Oda"
            className="w-full rounded-lg lg:h-72 h-[300px]"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 flex items-center justify-center rounded-2xl hover:opacity-100">
            <p className="text-white text-center text-lg font-bold">
            {t("wood")}
            </p>
          </div>
          </Link>
        </div>
      </div>

      <div className="w-full lg:w-1/3 flex justify-center">
        <div className="border rounded-2xl relative w-[100vh]">
          <Link to={'rooms/standart'}>
          <img
            src="/media/rooms/standart/standart1.jpg"
            alt="Ağva Otel - Ağva Villa Otel - Ağva Otel Odası"
            className="w-full rounded-lg lg:h-72 h-[300px]"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 flex items-center justify-center rounded-2xl hover:opacity-100">
            <p className="text-white text-center text-lg font-bold">
            {t("standart")}
            </p>
          </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
  )
}
