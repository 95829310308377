

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  const handleCall = () => {
    window.location.href = 'tel:+905522927803';
  };

  return (
    <footer className=" font-sans bg-gray-900 mt-10  relative">
      <div className="container px-6 py-12 mx-auto">
        <div className="flex flex-wrap">
          <div className="justify-center w-1/3">
            <h4 className="flex justify-start text-white text-md lg:text-2xl mb-5">
              {t("contact")}
            </h4>
            <div className="text-white text-sm lg:text-md">
              <p className="cursor-pointer" onClick={handleCall}>{t("phone")}+90 (552) 292 78 03 </p>
              {/* <p>{t("email")} : eeee.com</p> */}
              <p>{t("address")} : Kurfallı Mah, Erseven Sk. No:85, Agva, Istanbul, Turkiye</p>
            </div>
          </div>
          <div className="w-1/3 ">
            <h4 className="text-white text-md lg:text-2xl mb-3 text-center">{t("follow")}</h4>
            <div className="flex justify-center">
              <div className="flex gap-4 hover:cursor-pointer ml-2">
               <Link to="https://www.instagram.com/agvasaklibahceotel?igsh=MTc2Y3A1NngyNWF1YQ==">
                <img
                  src="https://www.svgrepo.com/show/303145/instagram-2-1-logo.svg"
                  className="lg:w-[60px] lg:h-[60px] w-[40px] h-[40px] text-center"
                  alt="inst"
                />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex w-1/3 justify-center">
            <img
              src="/media/icon.png"
              alt="Ağva Otel Logo"
              className="max-lg:w-24 w-36 h-36 max-lg:h-24  rounded-xl"
            />
          </div>
        </div>

        <hr className="my-3 border-gray-200 lg:my-8 dark:border-gray-700 h-2" />

        <p className="text-white font-sans lg:p-8 text-center text-sm lg:text-lg p-4">
          © 2024 Agva Saklı Bahçe. All rights reserved.
        </p>
        <p>
          <a
            href="https://www.linkedin.com/in/emir-cömert-55885b278"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center"
          >
            <div
              className="lg:text-lg text-sm bg-[#efefef] lg:p-2 p-1 rounded-full hover:bg-[#ff0366] hover:text-white flex items-center w-fit "
              style={{ transition: "all 0.3s" }}
            >
              Created by
              <img
                src="https://www.svgrepo.com/show/28145/linkedin.svg"
                width="30"
                height="30"
                alt="in"
                className="ml-2"
              />
            </div>
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
