import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { Carousel } from "react-responsive-carousel";
import { useEffect } from "react";
function Galery() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const images = [
    "./media/main.jpg",
    "./media/main2.jpg",
    "./media/main3.jpg",
    "./media/main4.jpg",
    "./media/main5.jpg",
    "./media/main6.jpg",
    "./media/galeri1.jpg",
    "./media/galeri2.jpg",
    "./media/galeri3.jpg",
    "./media/galeri4.jpg",
    "./media/galeri5.jpg",
    "./media/galeri7.jpg",
    "./media/galeri8.jpg",
    "./media/galeri9.jpg",
    "./media/galeri10.jpg",
    "./media/galeri11.jpg",
  ];
  const handleOpen = (selectedImage) => {
    setIsOpen(!isOpen);
    setSelectedImage(selectedImage);
    document.body.style.overflow = "hidden";
  };
  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "";
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        closeModal();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Ağva Otel Saklı Bahçe -  Doğayla İç İçe Tatil Deneyimi </title>
        <meta
          name="description"
          content="Ağva otelleri arasında konfor ve doğallığı bir arada bulabileceğiniz nadir mekanlardan biri. Ağva Saklı Bahçe Otel, eşsiz nehir manzarasıyla sizi karşılıyor. Ağva'nın huzur dolu atmosferinde konaklamak için ideal bir seçenek. Nehir kenarında yer alan villalarımızla doğayla iç içe bir tatil fırsatı sunuyoruz. Hemen rezervasyon yapın ve Ağva'nın büyüleyici manzarasını keşfedin! "
        />
        <meta property="og:title" content="Ağva Otel Saklı Bahçe -  Doğayla İç İçe Tatil Deneyimi" />
      </Helmet>
      <div className="mb-24">
        <Navbar />
        <div className="relative">
          <img
            src="/media/main3.jpg"
            alt="Ağva Otel - Ağva Saklı Bahçe Otel İletişim - Ağva Otel İletişim"
            className="w-full lg:h-[350px] h-[200px] object-cover"
          />
          <div class="absolute inset-0 bg-black opacity-50"></div>
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center mt-14">
            <p className="text-white md:text-4xl text-2xl font-thin tracking-wider font-serif uppercase block">
              {t("galery")}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div>
          <FloatingWhatsApp
            phoneNumber="+905522927803"
            accountName="Saklı Bahçe"
            avatar="./media/logo.png"
            chatMessage={t("wpgreeting")}
          />
        </div>

        <div className="text-center mb-10">
          <h1 className="text-3xl md:text-5xl italic font-serif">
            {t("galerytitle")}
          </h1>
        </div>
        <div className="container">
          <div className="grid lg:grid-cols-4 grid-cols-2">
            {images.map((image, index) => (
              <div className="p-2" key={index}>
                <img
                  src={image}
                  alt="Ağva Saklı Bahçe Otel Fotoğrafları - Ağva Manzara Fotoğrafları - Ağva Otel Resimleri"
                  className="w-full h-[170px] md:h-[200px] object-cover cursor-pointer"
                  onClick={() => {
                    handleOpen(index);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        {isOpen && (
          <div
            className="overlay fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 z-50"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              zIndex: 1000,
              overflow: "hidden"
            }}
            onClick={(e) => {
              !e.target.closest(".bg-green-100") && closeModal();
            }}
          >
            <div
              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-100 bg-opacity-55 py-2 md:py-5 rounded-md md:w-[70%] w-[100%]`}
            >
              <button
                onClick={closeModal}
                className="absolute top-0 right-0  md:p-1 bg-blue-200 rounded-full"
              >
                <RiCloseLine className="text-sm" />
              </button>
              <Carousel
                showThumbs={false}
                showArrows={true}
                dynamicHeight={true}
                useKeyboardArrows
                selectedItem={selectedImage}
                width={"100%"}
                infiniteLoop={true}
              >
                {images.map((image, index) => (
                  <div className="md:h-[90vh] h-[50vh]" key={index}>
                    <img
                      className="h-full object-contain "
                      src={image}
                      alt="Ağva Saklı Bahçe Otel Fotoğrafları - Ağva Manzara Fotoğrafları - Ağva Otel Resimleri"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Galery;
