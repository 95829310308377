import React from "react";
import Navbar from "../components/Navbar";
import CarouselMain from "../components/CarouselMain";
import HomeMain from "../components/HomeMain";
import RoomMain from "../components/RoomMain";
import Footer from "../components/Footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useTranslation } from "react-i18next";
import MainAnimation from "../components/MainAnimation";
import { Helmet } from "react-helmet";
function Home() {
  const { t } = useTranslation();
  return (
    <div className="h-full">
      <Helmet>
        <title>Ağva Saklı Bahçe - Ağva Otel - Ağva Otelleri</title>
        <meta
          name="description"
          content="Ağva Otel Saklı Bahçe, sakin ve huzurlu bir tatil için ideal mekânınız. Doğanın kalbinde, nehir kıyısında yer alan otelimizde unutulmaz anlar yaşayın. Ağva'nın benzersiz manzarası ve etkileyici atmosferiyle huzuru bulabileceğiniz bir kaçış noktası. Ağva otel deneyiminizi özel kılmak için buradayız. Hemen rezervasyon yapın ve doğanın sesini dinleyin!"
        />
        <meta property="og:title" content="Ağva Saklı Bahçe - Ağva Otel - Ağva Otelleri" />
        <meta
          property="og:description"
          content="Ağva Otel Saklı Bahçe, sakin ve huzurlu bir tatil için ideal mekânınız. Doğanın kalbinde, nehir kıyısında yer alan otelimizde unutulmaz anlar yaşayın. Ağva'nın benzersiz manzarası ve etkileyici atmosferiyle huzuru bulabileceğiniz bir kaçış noktası. Ağva otel deneyiminizi özel kılmak için buradayız. Hemen rezervasyon yapın ve doğanın sesini dinleyin!"
        />
      </Helmet>
      <div>
        <Navbar />
      </div>
      <div>
        <FloatingWhatsApp
          phoneNumber="+905522927803"
          accountName="Saklı Bahçe"
          avatar="./media/logo.png"
          chatMessage={t("wpgreeting")}
        />
      </div>

      <div className="w-full">
        <CarouselMain />
      </div>
      <MainAnimation />
      <div>
        <HomeMain />
      </div>
      <div>
        <RoomMain />
      </div>

      <Footer />
    </div>
  );
}

export default Home;
