import React from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'

export default function MainAnimation () {
    const { t } = useTranslation()
  return (
    <div className='mt-10'>

    
  <h1 className="block-effect bg-green-50" style={{ '--td': '2.5s' }}>
    <div className="block-reveal font-bold tracking-widest" style={{ '--bc': '#D7DDE8', '--d': '.1s' }}>{t("welcome")}</div>
    <div className="block-reveal font-serif italic tracking-widest" style={{ '--bc': '#1c8a5a', '--d': '.2s' }}>
      <h1>SAKLI BAHÇE AĞVA OTEL</h1></div>
  </h1>
  </div>

  )
}
