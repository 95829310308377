import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    Cookies.set("lng", language, { expires: 365 });
    i18n.changeLanguage(language);
  };

  return (
    <div className="flex justify-center rounded-md w-28">
      <button className="mr-5" onClick={() => changeLanguage("tr")}>
        <img
          className="lg:w-7 lg:h-7 w-5 h-5 hover:lg:h-8 hover:lg:w-8 hover:w-6 hover:h-6"
          src="/media/tr.png"
          alt="turkish-flag"
        />
      </button>
      <button onClick={() => changeLanguage("en")}>
        <img
          src="/media/usa.png"
          className="lg:w-7 lg:h-7 w-5 h-5 hover:lg:h-8 hover:lg:w-8 hover:w-6 hover:h-6"
          alt="usa-flag"
        />
      </button>
    </div>
  );
}

export default LanguageSwitcher;
