import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import { Link } from "react-router-dom";
import MenuModal from "./MenuModal";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdWhatsapp } from "react-icons/md";
import './styles.css';

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [toggleMenu , setToggleMenu] = useState(false);
  const { t } = useTranslation();
  //const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
 

  const handleMenu = () => {
    setToggleMenu(!toggleMenu); 
  }

  return (
    <div
      className={`fixed top-0 left-0 w-full z-50 ${
        scrolled
          ? "bg-gradient text-white bg-opacity-100 transition-all duration-500 shadow-2xl"
          : "bg-blue-100 bg-opacity-70 transition-all duration-500"
      }`}
    >
      <div className="container">
        <div
          className={`flex justify-between items-center py-4 transition-all duration-1000 ${!scrolled && 'h-28 lg:h-44'}  ${
            scrolled && "h-20 lg:h-32 transition-all duration-1000"
          }`}
        >
          <div className="text-2xl font-bold text-stone-900">
            <Link to={"/"}>
              <img
                className={`transition-all duration-500 ${scrolled ?  "lg:w-44 w-24" : "w-32 lg:w-56" }`}
                src="/media/icon.png"
                alt="Ağva Saklı Bahçe Otel - Ağva Otel - Ağva Villa Otel"
              />
            </Link>
          </div>

          <div className="flex items-center lg:space-x-20 xl:space-x-52 space-x-12 justify-between max-md:hidden">
            <div className="flex items-center space-x-6 lg:text-lg text-sm font-light">
              <Link to={"/"} className="text-stone-900 hover:italic">
                {t("home")}
              </Link>
              <Link to={"/rooms"} className="text-stone-900 hover:italic">
                {t("rooms")}
              </Link>
              <Link to={"/galery"} className="text-stone-900 hover:italic">
                {t("galery")}
              </Link>
              <Link to={"/contact"} className="text-stone-900 hover:italic">
                {t("contact")}
              </Link>
              <Link to={"https://api.whatsapp.com/send/?phone=%2B905522927803&type=phone_number&app_absent=0"} className="text-stone-900 hover:italic">
                <div className="flex items-start">
                {t("reservation")}
                <MdWhatsapp className="" />
                </div>
                
              </Link>
            </div>
            <div className="flex">
              <LanguageSwitcher />
            </div>
          </div>

          <div className="hidden max-md:flex mr-7">
            <GiHamburgerMenu color="black" onClick={handleMenu}/>
            {toggleMenu && <MenuModal setToggleMenu={setToggleMenu}/>}
          </div>
          
        </div>
      </div>
    </div>
  );
}
