import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { CiSquareMore } from "react-icons/ci";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { RiCloseLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import NotFoundPage from "./NotFoundPage";
import PropsDeluxe from "../components/Rooms/PropsDeluxe";
import PropsAhsap from "../components/Rooms/PropsAhsap";
import PropsStandart from "../components/Rooms/PropsStandart";
import { Helmet } from "react-helmet";

export default function RoomDeluxe() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { types } = useParams();
  let images = [];
  const { t } = useTranslation();
  let title = "Ağva Saklı Bahçe Otel"

  if (types === "deluxe") {
    title = "Ağva Saklı Bahçe Otel | Deluxe Oda";
    images = [
      `/media/rooms/${types}/${types}1.jpg`,
      `/media/rooms/${types}/${types}2.jpg`,
      `/media/rooms/${types}/${types}3.jpg`,
      `/media/rooms/${types}/${types}4.jpg`,
      `/media/rooms/${types}/${types}5.jpg`,
      `/media/rooms/${types}/${types}6.jpg`,
      `/media/rooms/${types}/${types}7.jpg`,
      `/media/rooms/${types}/${types}8.jpg`,
      `/media/rooms/${types}/${types}9.jpg`,
      `/media/rooms/${types}/${types}10.jpg`,
      `/media/rooms/${types}/${types}11.jpg`,
      `/media/rooms/${types}/${types}12.jpg`,
      `/media/rooms/${types}/${types}13.jpg`,
      `/media/rooms/${types}/${types}14.jpg`,
      `/media/rooms/${types}/${types}15.jpg`,
      `/media/rooms/${types}/${types}16.jpg`,
    ];
  } else if (types === "ahsap") {
    title = "Ağva Saklı Bahçe Otel | Ahşap Oda";
    images = [
      `/media/rooms/${types}/${types}1.jpg`,
      `/media/rooms/${types}/${types}2.jpg`,
      `/media/rooms/${types}/${types}3.jpg`,
      `/media/rooms/${types}/${types}4.jpg`,
      `/media/rooms/${types}/${types}5.jpg`,
      `/media/rooms/${types}/${types}6.jpg`,
      `/media/rooms/${types}/${types}7.jpg`,
      `/media/rooms/${types}/${types}8.jpg`,
      `/media/rooms/${types}/${types}9.jpg`,
    ];
  } else if (types === "standart") {
    title = "Ağva Saklı Bahçe Otel | Standart Oda";
    images = [
      `/media/rooms/${types}/${types}1.jpg`,
      `/media/rooms/${types}/${types}2.jpg`,
      `/media/rooms/${types}/${types}3.jpg`,
      `/media/rooms/${types}/${types}4.jpg`,
      `/media/rooms/${types}/${types}5.jpg`,
      `/media/rooms/${types}/${types}6.jpg`,
      `/media/rooms/${types}/${types}7.jpg`,
      `/media/rooms/${types}/${types}8.jpg`,
      `/media/rooms/${types}/${types}9.jpg`,
      `/media/rooms/${types}/${types}12.jpg`,
      `/media/rooms/${types}/${types}13.jpg`,
    ]
  }
  
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        closeModal();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsOpen(false);
    document.body.style.overflow = "";
  };

  

  if (types !== "deluxe" && types !== "ahsap" && types !== "standart") {
    return <NotFoundPage />;
  }

  return (
    <div>
       <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content= "Ağva Saklı Bahçe Otel'in eşsiz odalarında huzur ve konforu bir arada yaşayın. Doğanın kucağında huzurlu bir kaçamak için ideal adres! Göl manzaralı ve modern olanaklarıyla unutulmaz bir tatil için hazırız. Şimdi rezervasyon yapın ve Ağva'da hayalinizdeki tatili yaşayın!" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="Ağva Saklı Bahçe Otel'in eşsiz odalarında huzur ve konforu bir arada yaşayın. Doğanın kucağında huzurlu bir kaçamak için ideal adres! Göl manzaralı ve modern olanaklarıyla unutulmaz bir tatil için hazırız. Şimdi rezervasyon yapın ve Ağva'da hayalinizdeki tatili yaşayın!" />
        <meta property="og:image" content="./media/logo.png" />
      </Helmet>
      
      <Navbar />
      <div className="container md:mt-72 mt-32">
        <div id="agva-rooms">
          <div>
            <FloatingWhatsApp
              phoneNumber="+905522927803"
              accountName="Saklı Bahçe"
              avatar="/media/logo.png"
              chatMessage={t("wpgreeting")}
            />
          </div>
          <div className="md:grid md:grid-cols-2 md:grid-row-1 gap-2 my-5 cursor-pointer ">
            <div className="col-span-1 row-span-1">
              <img
                src={`/media/rooms/${types}/${types}1.jpg`}
                className="rounded-l-xl h-[30vh] md:h-[61vh] w-[100%] max-w-full object-cover"
                onClick={() => openModal(0)}
                alt="Ağva Otel - Ağva Villa Otel - Ağva Otel Odası"
              />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-1 grid grid-row-2 gap-y-2">
                <div className="row-span-1">
                  <img
                    src={`/media/rooms/${types}/${types}2.jpg`}
                    alt={`Ağva ${types} Oda`}
                    className="max-w-full  h-[20vh] md:h-[30vh] w-[100%]  object-cover"
                    onClick={() => openModal(1)}
                  />
                </div>
                <div className="row-span-1">
                  <img
                    src={`/media/rooms/${types}/${types}3.jpg`}
                    alt="Ağva Otel - Ağva Villa - Ağva Otel Odası - Ağva Otel Oda"
                    className="max-w-full  h-[20vh] md:h-[30vh] w-[100%]  object-cover"
                    onClick={() => openModal(2)}
                  />
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="col-span-1 grid grid-row-2 gap-y-2">
                  <div className="row-span-1 ">
                    <img
                      src={`/media/rooms/${types}/${types}4.jpg`}
                      alt="Ağva Otel - Ağva Villa - Ağva Otel Odası - Ağva Otel Oda Resmi"
                      className="max-w-full  h-[20vh] md:h-[30vh] w-[100%]  object-cover rounded-tr-xl"
                      onClick={() => openModal(3)}
                    />
                  </div>
                  <div className="row-span-1 relative  ">
                    <img
                      src={`/media/rooms/${types}/${types}5.jpg`}
                      alt="Ağva Otel - Ağva Villa Otel - Ağva Otel Odası"
                      className="max-w-full  h-[20vh] md:h-[30vh] w-[100%] object-cover rounded-br-xl  "
                      onClick={() => openModal(4)}
                    />
                    <div className="absolute bottom-5 right-5 whitespace-nowrap">
                      <button
                        onClick={() => openModal()}
                        className="border bg-gray-200 px-5 p-1 rounded-2xl font-light"
                      >
                        <div className="flex items-center">
                          <CiSquareMore className=" text-xl" />
                          <span>Tüm Fotografları Göster</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isOpen && (
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                zIndex: 1000,
                overflow: "hidden"
              }}
              onClick={(e) => {
                !e.target.closest(".bg-green-100") && closeModal();
              }}
            >
              <div
                className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-2 md:py-4 bg-green-100 bg-opacity-50 rounded-md md:w-[70%] w-[100%]`}
              >
                <button
                  onClick={closeModal}
                  className="absolute top-0 right-0 md:p-1 bg-blue-200 rounded-full"
                >
                  <RiCloseLine className="text-sm" />
                </button>
                <Carousel
                  showThumbs={false}
                  showArrows={true}
                  dynamicHeight={true}
                  useKeyboardArrows
                  selectedItem={selectedImage}
                  width={"100%"}
                >
                  {images.map((image, index) => (
                    <div className="md:h-[90vh] h-[50vh]" key={index}>
                      <img
                        className="h-full w-full object-contain"
                        src={image}
                        alt={`Ağva ${types} Oda`}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          )}
          <div className="my-10">
            {types === "deluxe" && <PropsDeluxe />}
            {types === "ahsap" && <PropsAhsap />}
            {types === "standart" && <PropsStandart />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
