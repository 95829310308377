import React from "react";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { MdWhatsapp } from "react-icons/md";

import  './styles.css'
import LanguageSwitcher from "./LanguageSwitcher";

const MenuModal = ({ setToggleMenu }) => {
  const { t } = useTranslation();
  const handleMenu = () => {
    setToggleMenu(false);
  };

  return (
    <div className="fixed top-0 right-0 transform text-center navbar-modal p-8 z-50 h-full w-1/2 content-start ">
      <div className="absolute top-0 right-0 mt-2 mr-2">
        <button onClick={handleMenu} className="text-gray-600">
          <RiCloseLine size={44} />
        </button>
      </div>
      <div className="mt-24">
        <ul className="space-y-8 text-xl">
          <li className="transform hover:scale-105 transition-transform duration-300">
            <a href="/" className="text-gray-800 hover:text-gray-600">
              {t("home")}
            </a>
          </li>
          <li className="transform hover:scale-105 transition-transform duration-300">
            <a href="/rooms" className="text-gray-800 hover:text-gray-600">
              {t("rooms")}
            </a>
          </li>
          <li className="transform hover:scale-105 transition-transform duration-300">
            <a href="/galery" className="text-gray-800 hover:text-gray-600">
              {t("galery")}
            </a>
          </li>
          <li className="transform hover:scale-105 transition-transform duration-300">
            <a href="/contact" className="text-gray-800 hover:text-gray-600">
              {t("contact")}
            </a>
          </li>
          <li className="transform hover:scale-105 transition-transform duration-300 ">
            <a href="https://api.whatsapp.com/send/?phone=%2B905522927803&type=phone_number&app_absent=0" className="text-gray-800 hover:text-gray-600">
              {t("reservation")}
              <MdWhatsapp className="inline-block ml-1 text-2xl" />
            </a>
          </li>
          <li className="flex justify-center">
           <LanguageSwitcher />
          </li>

        </ul>
      </div>
    </div>
  );
};

export default MenuModal;
