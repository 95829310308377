import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Helmet } from "react-helmet";

export default function Contact() {
  const { t } = useTranslation();

  const handleCall = () => {
    window.location.href = "tel:+905522927803";
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Helmet>
        <title>Ağva Saklı Bahçe Otel | İletişim</title>
        <meta
          name="description"
          content="Bize ulaşın ve unutulmaz bir tatil için adımınızı atın! Ağva Saklı Bahçe Otel'e dair merak ettikleriniz ve rezervasyonlarınız için bizimle iletişime geçin. Size en iyi hizmeti sunmak için buradayız."
        />
        <meta property="og:title" content="Ağva Saklı Bahçe Otel | İletişim" />
      </Helmet>

      <Navbar />

      <div>
        <FloatingWhatsApp
          phoneNumber="+905522927803"
          accountName="Saklı Bahçe"
          avatar="./media/logo.png"
          chatMessage={t("wpgreeting")}
        />
      </div>
      <div>
        <div className="relative">
          <img
            src="/media/main6.jpg"
            alt="Ağva Otel - Ağva Villa Otel - Ağva Otel Odası"
            className="w-full lg:h-[350px] h-[200px] object-cover"
          />
          <div class="absolute inset-0 bg-black opacity-50"></div>
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center mt-14">
            <p className="text-white md:text-4xl text-2xl font-thin italic tracking-wider font-serif uppercase block">
              {t("contact")}
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto md:py-16 py-10">
        <div className="my-10 text-center">
          <h1 className="text-3xl md:text-5xl italic font-serif">
            AĞVA SAKLI BAHÇE OTEL
          </h1>
        </div>
        <div>
          <div className="mx-auto bg-white shadow-md rounded-md overflow-hidden">
            <div className="bg-green-700 bg-opacity-90 py-4 px-6">
              <h2 className="md:text-3xl text-xl font-bold text-white mb-2 border-b max-md:text-center">
                {t("visit")}
              </h2>
              <p className="text-black md:text-lg text-md max-md:text-center">
                {t("visitdesc")}
              </p>
              <p className="text-black md:text-lg text-md max-md:text-center">
                {t("visitdesc2")}
              </p>
            </div>
            <div className="p-6 flex justify-center max-md:space-x-10 md:space-x-96 bg-blue-50">
              <div className="text-center">
                <p className="font-semibold mb-2">{t("address")}</p>
                <div className="border-b-2 border-gray-300 mb-4 mx-auto w-16"></div>
                <p className="text-gray-800">Kurfallı Mah, Erseven Sk. No:85</p>
                <p className="text-gray-800">34983 Şile/İstanbul</p>
              </div>
              <div className="text-center">
                <p className="font-semibold mb-2">{t("phone")}</p>
                <div className="border-b-2 border-gray-300 mb-4 mx-auto w-16"></div>
                <p
                  className="text-gray-800 cursor-pointer"
                  onClick={handleCall}
                >
                  +90 (552) 292 78 03
                </p>
              </div>
            </div>
            <div className="flex justify-center bg-blue-50">
              <div className="text-center">
                <p className="font-semibold mb-2">{t("follow")}</p>
                <div className="border-b-2 border-gray-300 mb-4 mx-auto w-16"></div>
                <Link
                  className="flex justify-center mb-5"
                  to="https://www.instagram.com/agvasaklibahceotel?igsh=MTc2Y3A1NngyNWF1YQ=="
                >
                  <img
                    src="https://www.svgrepo.com/show/303145/instagram-2-1-logo.svg"
                    className="lg:w-[50px] lg:h-[50px] w-[40px] h-[40px] text-center"
                    alt="inst"
                  />
                </Link>
              </div>
            </div>
            <div className="mb-4">
              <div className="md:h-96 h-[90vh]">
                <div>
                  <h2 className="text-3xl font-thin text-center mt-10 text-red-500">
                    {t("directionYandex")}
                  </h2>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-2 gap-5 h-full mt-5">
                  <iframe
                    title="Map"
                    src="https://yandex.com.tr/map-widget/v1/-/CDSBv2ys"
                    width="100%"
                    height="100%"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <iframe
                    title="Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3005.631016265538!2d29.846976499999993!3d41.1207489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409e3bf385cb82eb%3A0xd12cdc2cad6eef49!2zQcSfdmEgU2FrbMSxIEJhaMOnZSBPdGVs!5e0!3m2!1str!2str!4v1713124429974!5m2!1str!2str"
                    width="100%"
                    height="100%"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
